@font-face {
  font-family: 'Cera Pro Light';
  src: url('../../assets/fonts/Cera-Pro-Light.otf') format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro Medium';
  src: url('../../assets/fonts/Cera-Pro-Medium.otf') format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro Bold';
  src: url('../../assets/fonts/Cera-Pro-Bold.otf') format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: url('../../assets/fonts/Oswald-VariableFont_wght.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}


