:root {
  --primary-main-color: #D51022;
  --primary-dark-color: #256320;
  --primary-light-color: #EBFFD9;

  --black: #101111;
  --white: #FFFFFF;
  --gray-main: #9E9E9E;
  --gray-dark: #525252;
  --gray-light: #F2F2F2;
}
