/* You can add global styles to this file, and also import other style files */
@import 'styles/main';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
  touch-action: manipulation;
}

body {
	font-family: 'Cera Pro Medium', sans-serif;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
}

button {
	font-family: 'Cera Pro Medium', sans-serif;
	background: transparent;
	border: none;
	cursor: pointer;
}

.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
	/* задний фон под игрой, чтобы не было белого экрана на планшете и десктопе. надо будет потом заменить на актуальный для этой игры! */
  background: url("assets/images/desktop-bg.png") no-repeat center;
  background-size: cover;
}

.main {
	width: 100%;
	height: 100%;
	//min-height: 640px;
	background-color: #ffffff;
	position: relative;
}

@media only screen and (min-width: 585px) {
	.main {
		border-radius: 30px;
		width: 300px;
		height: 650px;
	}

	.main::after {
		content: '';
		width: 331px;
		height: 678px;
		position: absolute;
		top: -14px;
		left: -14px;
		background-image: url('assets/images/phone.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		pointer-events: none;
		z-index: 99;
	}
}

// Общие стили для кнопок

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 49px;
	width: 100%;
	border-radius: 2px;
	text-align: center;
	color: #fff;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	cursor: pointer;
  border: 1px solid #000000;
  box-shadow: 5px 5px 0 0 #000000;
  transition:
		color 300ms,
		border 300ms,
		background 300ms;

	&._blue {
		background: #3A6CE8;
	}

	&._white {
		background: #EEEEEE;
		color: #222222;
	}

	&._disabled {
		background: transparent;
    border: 1px solid #76767680;
		pointer-events: none;
    color: #76767680;
  }
}

// стили для модалок и кнопки закрытия модалок
.modal-wrapper {
	position: absolute;
	inset: 0;
	background: rgba(0, 0, 0, 0.85);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 99;
	gap: 10px;

	.video-wrapper {
    width: calc(100% - 40px);
    border: 3px solid #222222;
    border-radius: 2px;

		video {
			width: 100%;
      height: 100%;
      object-fit: cover;
			border-radius: 2px;
		}
	}

	@media only screen and (min-width: 585px) {
		border-radius: 20px;
	}
}

.modal-inner {
	position: relative;
	padding: 36px 20px 20px 20px;
	border-radius: 2px;
	border: 1px solid #222;
	background: #fff;
	width: calc(100% - 40px);
	height: auto;
}

.close {
	position: absolute;
	width: 40px;
	height: 40px;
	top: -8px;
	right: -8px;
	cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.final-text {
	font-size: 14px;
	flood-color: #101111;
	font-weight: 700;
}
